import { render, staticRenderFns } from "./dashboardTransactions.html?vue&type=template&id=1ae44bad&scoped=true&"
import script from "./dashboardTransactions.js?vue&type=script&lang=js&"
export * from "./dashboardTransactions.js?vue&type=script&lang=js&"
import style0 from "./dashboardTransactions.scss?vue&type=style&index=0&id=1ae44bad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae44bad",
  null
  
)

export default component.exports