import { mapActions, mapGetters } from "vuex";
import * as types from "./../../store/types";
import transactionsService from "../../services/transactionsService";

const orderList = () => import("./../orderList/index.vue");
export default {
    name: "dashboard-transactions",
    components: {
        orderList,
    },
    props: [],
    data() {
        return {
            tableHeaders: [],
        };
    },
    computed: {
        ...mapGetters({
            isTransActionsLoaded: types.GET_IS_TRANSACTIONS_LOADED,
            transactions: types.GET_TRANSACTIONS,
        }),
        filteredTransactions() {
            if (this.transactions) {
                return this.transactions.content.slice(0, 5);
            }
        },
    },
    mounted() {},
    methods: {
        ...mapActions({
            transactionsAction: types.ACTION_TRANSACTIONS,
        }),
        getTransactionsData() {
            transactionsService
                .getTransactions(localStorage.getItem("bitrahAccessToken"), 0)
                .then((response) => {
                    this.transactionsAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
};
